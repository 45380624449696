<template>
  <main class="page-users page-show-user">
    <PageTitleContainer>
      <PageTitle removeMargin>{{ user.name }}</PageTitle>

      <div class="mb-3 text-right" v-if="isAdmin">
        <router-link class="btn btn-outline-primary" :to="{ name: 'users.edit' }">Modifica</router-link>
      </div>
    </PageTitleContainer>

    <div class="container">
      <div class="table-responsive">
        <table class="table table-condensed visea-info-table">
          <tbody>
          <tr>
            <td class="title">Nome</td>
            <td>{{ user.name }} {{ user.surname }}</td>
          </tr>
          <tr>
            <td class="title">Email</td>
            <td>{{ user.email }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </main>
</template>

<script>

import rolesMixin from '../../../mixins/roles';

export default {
  name: 'user-show',
  mixins: [rolesMixin],
  components: {
    PageTitle: () => import('../../components/Layout/PageTitle'),
    PageTitleContainer: () => import('../../components/Layout/PageTitleContainer'),
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
};

</script>
